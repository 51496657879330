import React, { Component, Fragment } from "react";

// * Material UI import
import { Typography, Grid, Box, withStyles, CssBaseline } from "@material-ui/core";

// Component
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Menu from "../Component/Menu";

import PropTypes from "prop-types";

// Image
import AbstractImg from "../../Assets/Images/background_abstract.png";
import PonKangpoImg from "../../Assets/Images/image-pon-kangpo.png";
import PapuaBangkitImg from "../../Assets/Images/papua_bangkit.png";
import KangphoImg from "../../Assets/Images/kangpho_white.png";

class About extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Menu
          titleMenu={
            <div>
              <Typography className={classes.txtTitle}>TENTANG PON XX</Typography>
              <div style={{ marginBottom: 40 }} />
            </div>
          } />
        {/* menu unit */}
        <main
          style={{
            backgroundImage: "linear-gradient(to right, #ffffff , #c4c4c4 )",
            height: "100%"
          }}
        >
          <div className={classes.container}>
            <div className={classes.containerResponsive}>
              <Grid
                container
                style={{
                  borderBottom: "2px solid #707070",
                  paddingBottom: 50,
                  marginBottom: 50
                }}
              >
                <Grid item xs={12} sm={6} md={4} lg={4} style={{marginBottom:10}}>
                  <Box boxShadow={3} style={{ width: "100%" }}>
                    <img
                      alt="img"
                      src={PonKangpoImg}
                      style={{ width: "100%", borderRadius: 5 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={1} lg={1} />
                <Grid container xs={12} sm={6} md={4} lg={4}>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: 20 }}>
                      Konsep Dasar Logo PON XX PAPUA 2021
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 100,
                        marginBottom: 10
                      }}
                    >
                      Stadion Papua Bangkit adalah simbol kemegahan dibidang
                      olahraga rakyat Papua yang sebentar lagi akan selesai
                      pengerjaannya.
                    </Typography>
                    <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                      Stadion yang dibangun untuk PON 2021 ini terbilang sangat
                      megah. Bahkan nyaris menyamai Stadion Utama GBK Senayan,
                      bahkan disebut-sebut memiliki kemiripan dengan stadion
                      sepak bola terbesar kedua didunia, Salt Lake di India.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ paddingBottom: 50 }} spacing={4}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography style={{ marginBottom: 20 }}>
                    Makna & Filosofi Logo PON XX PAPUA
                  </Typography>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 100, marginBottom: 20 }}
                  >
                    Stadion Papua Bangkit
                  </Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                    Memiliki bentuk stadion kebanggaan Papua yang Merupakan
                    stadion termegah di Indonesia sebagai symbol wadah tempat
                    pemersatu
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 100,
                      marginTop: 20,
                      marginBottom: 20
                    }}
                  >
                    Motif Khas Papua
                  </Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                    Mewakili keragaman budaya Papua yang mempunyai bentuk khas
                    dari ukiran Papua
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: 43 }}>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 100, marginBottom: 20 }}
                  >
                    Tiga Lingkaran/Ring
                  </Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                    Arti dari tiga lingkaran adalah prestasi dan solidaritas
                    akan menjadi pemersatu rakyat dalam olahraga akan tetap
                    kokoh dan abadi bila didasari prinsip yang berkebangsaan
                    satu berbahasa satu dan bertanah air satu. INDONESIA,
                    sekaligus mewakili 3 ring dalam logo KONI sebagai wadah
                    induk olahraga prestasi Indonesia.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 100,
                      marginTop: 20,
                      marginBottom: 20
                    }}
                  >
                    Segitiga Menjulang/Gunung{" "}
                  </Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                    Papua memiliki banyak sumber kekayaan alam yang melimpah
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: 35 }}>
                  <img
                    alt="img"
                    src={PapuaBangkitImg}
                    style={{ width: "90%", height: 200 }}
                  />
                </Grid>
              </Grid>

              <Grid container style={{ paddingBottom: 50 }} spacing={4}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography style={{ marginBottom: 20 }}>
                    Makna & Filosofi Maskot Kangpho
                  </Typography>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 100, marginBottom: 20 }}
                  >
                    Kangpho (Kangguru Pohon Mantel Emas)
                  </Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                    Kangguru yang selama ini populer sebagai satwa khas
                    Australia, nyatanya ada juga di Papua. Menariknya, kangguru
                    ini merupakan jenis kangguru pohon. Dari sejumlah jenis
                    kangguru pohon tersebut, satu yang familiar adalah kangguru
                    pohon mantel emas atau Dendrolagus pulcherrimus.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 100,
                      marginTop: 20,
                      marginBottom: 20
                    }}
                  >
                    Obor
                  </Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                    Menunjukan semangat yang kuat dan menyala-nyala bagai api
                    untuk bertanding merebut prestasi dengan menjunjung tinggi
                    sportifitas.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: 43 }}>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 100, marginBottom: 20 }}
                  >
                    Puncak Salju
                  </Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                    Adalah lambang ciri khas pegunungan Papua
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 100,
                      marginTop: 20,
                      marginBottom: 20
                    }}
                  >
                    Warna Kuning Keemasan
                  </Typography>
                  <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                    Adalah lambang kejayaan hasil
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: 35 }}>
                  <img
                    alt="img"
                    src={KangphoImg}
                    style={{ width: "90%", height: 380 }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

About.propTypes = {
  classes: PropTypes.object.isRequired
};

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      background: "none"
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%"
    }
  },
  containerResponsive: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 10
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 40
    },
    paddingTop: 40
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 27,
    },
  }
});

export default withStyles(useStyles)(About);
