import React, { Component, Fragment } from "react";
import _ from 'lodash';
import { NavLink } from "react-router-dom";

// * Material UI import
import {
  Typography,
  Grid,
  Button,
  TextField,
  CssBaseline,
  ListItemText
} from "@material-ui/core";

// Component
import Header from '../Component/Header';
import Footer from '../Component/Footer';

// Image
import AbstractImg from "../../Assets/Images/background_abstract.png";

// Icons
import SearchIcon from '@material-ui/icons/Search';

import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FolderIcon from '@material-ui/icons/Folder';
import Divider from '@material-ui/core/Divider';
import Menu from "../Component/Menu";

import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { LanguageContext } from "../../Context/languageContext";
import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"

class News extends Component {
  static contextType = LanguageContext
  constructor() {
    super();
    this.state = {
      news: [],
      news_category: [],
      news_tag: ["PON XX", "Papua", "Menpora", "Kabupate Jayapura", "Venue", "Persiapan PON"],
      currentPage: 1,
      itemsPerPage: 12,
      search: "",
    };
    this.filternews = [];
  }

  async componentDidMount() {
    console.log('component did mount');
    await this.getData();
    await this.handleSubmit();
  }

  getData() {
    // var {category} =  this.props.match.params
    // if (category) {
    //   var newData = this.props.news.filter(function (item) {
    //     var itemData = item.category ? item.category.toUpperCase() : ''.toUpperCase();
    //     var textData = category.toUpperCase();
    //     return itemData.indexOf(textData) > -1;
    //   });
    //   this.setState({ news: newData });
    // }
    // else {
      this.setState({ news: this.props.news });
    // }
    this.filternews = this.props.news;

    // Api.getNews().then(resData=>{
      this.changeData(this.props.news);
    //   var data = resData.data;
    //   if (this.props.category) {
    //     var newData = data.filter(function (item) {
    //       var itemData = item.category ? item.category.toUpperCase() : ''.toUpperCase();
    //       var textData = this.props.category.toUpperCase();
    //       return itemData.indexOf(textData) > -1;
    //     });
    //     this.setState({ news: newData });
    //   }
    //   else {
    //     this.setState({ news: data });
    //   }
    //   this.filternews = data;
    // })
  }

  handleChange(e) {
    this.getData();
    this.setState({ [e.target.name]: e.target.value });
    console.log('search', e.target.value)
  }

  handleSubmit = async () => {
    var dataNews = await this.state.news;
    if (this.state.search !== '') {
      var txtsearch = this.state.search;
      var dataSearch = dataNews.filter(function (item) {
        return item.title.toString().toLowerCase().indexOf(txtsearch.toString().toLowerCase()) > -1;
      })
      dataNews = dataSearch
      this.setState({ news: dataNews });
    }
    else if (this.state.search === '') {
      this.getData();
    }
    else {
      this.setState({ news: [] });
    }
  }

  changeCurrentPage = numPage => {
    this.setState({ currentPage: numPage });
  };

  changeData(newData) {
    const result = _.chain(newData)
      .groupBy("category")
      .toPairs()
      .map(pair => _.zipObject(['category', 'news'], pair))
      .value();
    this.setState({ news_category: result });
  }

  chooseCategory(category) {
    this.props.history.push("/news/" + category + "/#")
    var data = this.filternews;
    var newData = data.filter(function (item) {
      var itemData = item.category ? item.category.toUpperCase() : ''.toUpperCase();
      var textData = category.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ news: newData });
  }

  render() {
    const { classes } = this.props;
    // const { category } = this.props.match.params;
    const { Languages } = this.context
    let { news, currentPage, itemsPerPage } = this.state;
    // const indexOfLast = currentPage * itemsPerPage;
    // const indexOfFirst = indexOfLast - itemsPerPage;

    // const sizepage = Math.ceil(news.length / itemsPerPage);

    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Menu
          titleMenu={
            
            <Typography component={NavLink} to="/news" className={classes.txtTitle}>{Languages === "Bahasa Indonesia" ?  "Semua Berita " : "News"} {this.props.category}</Typography>
          }
          searchBar={
            <div>
              <TextField
                margin="normal"
                variant="outlined"
                label={false}
                placeholder={Languages === "Bahasa Indonesia" ? "Cari berita apa?" : "Search"}
                type="search"
                className={classes.txtSearch}
                onChange={(e) => this.handleChange(e)}
                name="search"
                value={this.state.search}
                InputProps={{
                  className: classes.multilineColor
                }}
              />
              <Button onClick={this.handleSubmit} className={classes.btnSearch}><SearchIcon style={{ color: '#ffffff' }} /></Button>
            </div>
          }
        />
        {/* menu unit */}
        <main style={{ backgroundImage: 'linear-gradient(to right, #ffffff , #c4c4c4 )', height: "100%" }}>

          <div className={classes.container}>

            <Grid container className={classes.responsive}>
              <Grid item md={8} sm={12}>

                {/* <BreadCrumbs
                  firstName="Berita"
                  secondName={category}
                  colorFirstLink="inherit"
                  activeLink="textPrimary"
                  firstLink="/news"
                  secondLink="/news/news-detail"
                /> */}

                <Grid container spacing={4} style={{ marginBottom: 70 }}>
                  {
                    news.slice((currentPage * itemsPerPage - itemsPerPage), (currentPage * itemsPerPage)).map((item, index) => {
                      return (
                        <Grid item key={index} md={4} sm={4} xs={12} className={classes.containerNews} component={NavLink} to={"/news/news-detail/" + item.category + "/" + item.title + "/#"}>
                          <Grid container>
                            <Grid item xs={12}><div className={classes.sizeImg}><div style={{ backgroundImage: `url("${StaticVar.ImageUrl + item.picture}")`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: 'auto', minHeight: '100%', width: '100%', backgroundPosition: 'center', borderRadius: 10 }} /></div></Grid>
                            <Grid item xs={6}>
                              <Typography style={{ fontSize: 12, fontWeight: 200, textAlign: 'left', color: '#000000' }}>{item.create_date}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography style={{ fontSize: 12, fontWeight: 200, textAlign: 'right', color: '#000000' }}>{Languages === "Bahasa Indonesia" ? item.category : item.categoryEN }</Typography>
                            </Grid>
                            <Grid item xs={12}><Typography style={{ fontSize: 14, fontWeight: 300, color: '#000000' }}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN }</Typography></Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                  <center>
                    <Pagination
                      currentPage={this.state.currentPage}
                      totalSize={news.length}
                      sizePerPage={itemsPerPage}
                      changeCurrentPage={this.changeCurrentPage}
                      // firstPageText="Pertama"
                      // lastPageText="Terakhir"
                      showFirstLastPages={true}
                      // nextPageText="Berikutnya"
                      // previousPageText="Sebelumnya"
                      theme="bootstrap"
                    />
                  </center>
                </Grid>
              </Grid>

              <Grid item md={1} />

              <Grid item md={3} sm={12}>
                <Typography style={{ fontSize: 18, fontWeight: 600, marginBottom: 20 }}>{Languages === "Bahasa Indonesia" ? "Kategori Berita" : "News Category"}</Typography>
                <List className={classes.root}>
                  {
                    this.state.news_category.map((item, index) => {
                      return (
                        <div key={index}>
                          <ListItem button onClick={() => this.chooseCategory(item.category)}>
                            <ListItemIcon>
                              <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary={Languages === "Bahasa Indonesia" ? item.category : item.category } />
                            <ListItemSecondaryAction>
                              {item.news.length}
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </div>
                      )
                    })
                  }
                </List>

                <Typography style={{ fontSize: 18, fontWeight: 600, marginTop: 20, marginBottom: 20 }}>Tag</Typography>
                <div style={{ marginTop: 10, marginBottom: 30 }}>
                  {
                    this.state.news_tag.map((item, index) => {
                      return (
                        <Button key={index} style={{ backgroundColor: "#ED3237", color: 'white', fontSize: 13, margin: 1, borderRadius: 20 }}>{item}</Button>
                      )
                    })
                  }
                </div>
              </Grid>
            </Grid>
          </div>

        </main>
        <Footer />
      </Fragment>
    );
  }
}

News.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = theme => ({
  root: {
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  },
  responsive: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 20
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
      paddingRight: 40
    },
    paddingTop: 30,
    paddingBottom: 40
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      background: 'none'
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%'
    }
  },
  multilineColor: {
    color: '#000'
  },
  containerNews: {
    [theme.breakpoints.down('sm')]: {
      height: 270,
      marginBottom: 30,
    },
    [theme.breakpoints.up('md')]: {
      height: 250,
      marginBottom: 35,
    }
  },
  txtSearch: {
    [theme.breakpoints.down('xs')]: {
      width: '75%',
      marginBottom: 10
    },
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      marginBottom: 10
    },
    [theme.breakpoints.up('md')]: {
      width: '85%',
      marginBottom: 30
    },
    backgroundColor: '#fff',
  },
  btnLainnya: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    border: '2px solid #bf272b', borderRadius: 5,
    height: 48
  },

  txtLainnya: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 15
    },
    textTransform: 'none', color: '#bf272b'
  },

  sizeImg: {
    [theme.breakpoints.down('sm')]: {
      height: 210
    },
    [theme.breakpoints.up('md')]: {
      height: 180
    },
    width: '100%',
  },

  btnSearch: {
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
    [theme.breakpoints.up('md')]: {
      width: '15%',
    },
    backgroundColor: "#7d7d7d",
    height: 57
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 27,
    },
  },

  formLabelRoot: {
    // '&$focused': { color: 'green' }, // not working
    color: 'cyan', // this is working
  },
  formLabelFocused: {
    // '&$focused': { color: 'pink' }, // not working
    color: 'blue', // not working
  },
})

export default withStyles(useStyles)(News);