import React, {createContext, useReducer, useEffect} from 'react'
export const LanguageContext = createContext()
const LanguageContextProvider = (props) =>{
    const [Languages, Languagedispatch] = useReducer(ContextReducer,"Bahasa Indonesia")   
    
    useEffect(() => {
        if(localStorage.lang){
            Languagedispatch({type:'SET_DATA', data : localStorage.lang })
        }
    }, [])  
    // if(Languages.length === 0){
    //     console.log('Languages')
    //     Api.getLanguages().then(res=>Languagedispatch({type:"SET_DATA", data:res.data}))
    // }
    return(
        <LanguageContext.Provider value={{Languages, Languagedispatch}}>
            { props.children }
        </LanguageContext.Provider>
    )
}
const ContextReducer = (state, action)=>{
    switch(action.type){
        case 'SET_DATA': 
            return state = action.data
        default:
            return state
    }
}

export default LanguageContextProvider

