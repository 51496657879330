import 'babel-polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MultiProvider from "./Context/MultiProvider"
import LanguageProvider from './Context/languageContext';
import NewsProvider from './Context/NewsContext';
import PhotoProvider from './Context/PhotoContext';
import VideoProvider from './Context/VideoContext';
import HighligthProvider from './Context/HighligthContext'
import SponsorProvider from './Context/SponsorContext'

ReactDOM.render(
    <MultiProvider
        providers={[
            <LanguageProvider/>,
            <NewsProvider/>,
            <PhotoProvider/>,
            <VideoProvider/>,
            <HighligthProvider/>,
            <SponsorProvider/>
        ]}>
        <App/>
    </MultiProvider>

, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
