import React, {createContext, useReducer, useEffect} from 'react'
import Api from "../Services/Api"
export const NewsContext = createContext()

const localState = JSON.parse(localStorage.getItem("news"));

const NewsContextProvider = (props) =>{
    
    const [News, Newsdispatch] = useReducer(ContextReducer, localState || [])  
    useEffect(() => {
        // console.log('dataNews', dataNews)
        if(News.length === 0){
            Api.getNews().then(res=>{
                Newsdispatch({type:'SET_DATA', data: res.data});
                localStorage.setItem("news", JSON.stringify(res.data));
            })
        }
    }, [News]);
    return(
        <NewsContext.Provider value={{News, Newsdispatch}}>
            { props.children }
        </NewsContext.Provider>
    )
}
const ContextReducer = (state, action)=>{
    switch(action.type){
        case 'SET_DATA': 
            return state = action.data
        default: {
            return state
        }
    }
}

export default NewsContextProvider

