import React, { Component, useContext } from "react";

// * Material UI import
import {
  Typography,
  Drawer,
  Grid,
  AppBar,
  Toolbar,
  Button,
  ClickAwayListener,
  Paper,
  Hidden,
  withStyles,
  Select,
  Divider,
} from "@material-ui/core";

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import axios from 'axios'

import CssBaseline from '@material-ui/core/CssBaseline';

import PropTypes from 'prop-types';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DehazeIcon from '@material-ui/icons/Dehaze';
import CloseIcon from '@material-ui/icons/Close';

import { NavLink, Redirect } from "react-router-dom";
import logo from "../../Assets/Images/logo-2021.png";
import backgroundTopNavImg from "../../Assets/Images/aksen_header_menu.png"

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import _ from 'lodash';
import { MenuItem } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { LanguageContext } from "../../Context/languageContext";

import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"
// const url = "http://localhost:301";
// const {Languages, Languagedispatch} = useContext(LanguageContext)

class Header extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props);

    this.state = {
      setDropDownBerita: false,
      setDropDownLayanan: false,
      setDropDownPendaftaran: false,
      menuMobile: false,
      event_support: [],
      sitemap: [],
      language : 'Bahasa Indonesia'
    };


  };

  getDataSiteMap() {
    Api.getSitemap().then(res=>{
        this.setState({ sitemap: res.data })
        console.log('isi data : ', JSON.stringify(res.data));
    })
    // axios.get(url + "/sitemap").then(
    //   (res) => {
    //     this.setState({ sitemap: res.data })
    //     console.log('isi data : ', JSON.stringify(res.data));
    //   }
    // )
  }

  openDropDownLayanan() {
    this.setState(prevState => ({
      setDropDownLayanan: !prevState.setDropDownLayanan
    }));
  }

  closeDropDownLayanan() {
    this.setState({
      setDropDownLayanan: false
    });
  }

  openDropDownPendaftaran() {
    this.setState(prevState => ({
      setDropDownPendaftaran: !prevState.setDropDownPendaftaran
    }));
  }

  closeDropDownPendaftaran() {
    this.setState({
      setDropDownPendaftaran: false
    });
  }

  openMenuMobile() {
    this.setState(prevState => ({
      menuMobile: !prevState.menuMobile
    }));
  }

  closeMenuMobile() {
    this.setState({ menuMobile: false })
  }


  componentWillMount() {
    Api.getSitemap().then(res=>{
      this.setState({ sitemap: res.data })
    })
    // axios.get(url + "/sitemap").then(
    //   (res) => {
    //     this.setState({ sitemap: res.data })
    //   }
    // )
  }

  render() {
    const { classes } = this.props;
    const {Languages, Languagedispatch} = this.context

    // console.log('Languages',Languages)
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar className={classes.headerBox}>
          <Toolbar style={toolbar}>
            <Grid container>
              <Button
                component={NavLink} to="/"
                className={classes.btnlogoStyle}
              >
                <img src={logo} alt="Logo" className={classes.imglogoStyle} />
              </Button>

              <Grid container style={{ height: 44, paddingRight: 50 }}>
                <Grid item xs={11} sm={11} md={9} lg={9}>
                  <img src={backgroundTopNavImg} alt="Background Top nav" style={{ width: '100%', height: 44 }} />
                  <Hidden smDown>
                    <Typography style={{position:'absolute',left:235,top:10,textTransform:'uppercase',color:'#ffffff'}}>{Languages === "Bahasa Indonesia" ? "WEBSITE RESMI PON XX 2021 PAPUA" : "OFFICIAL WEBSITE PON XX 2021 PAPUA"} </Typography>
                  </Hidden>
                </Grid>

                <Hidden smDown>
                  <Grid container spacing={2} item xs={1} sm={1} md={3} lg={3} style={{ paddingLeft: 10, paddingTop: 9 }}>
                    
                    <Grid item md={7} style={{ position: 'relative' }}>
                      <ClickAwayListener onClickAway={() => this.closeDropDownPendaftaran()}>
                        <div>
                          {
                            this.state.sitemap.filter(x=> (x.position === "Button Header" || x.position === "Button Header & Footer") && x.parent === "Tanpa Parent").map(item=>{
                              return(
                                <>
                                <Button style={{ width: '100%', height: 32, backgroundColor: '#bf272b', padding: 0, paddingTop: 3 }} onClick={() => this.openDropDownPendaftaran()}>
                                  <Typography className={classes.txtPendaftaran} style={{fontSize:13}}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN }</Typography>
                                  <ExpandMoreIcon style={{ color: '#ffffff' }} />
                                </Button>
                                
                                </>
                              )
                            })
                          }
                          {
                            this.state.setDropDownPendaftaran ? (
                              <Paper
                                style={dropDownStylePendaftaran}
                              >
                                <div>
                                {
                                    this.state.sitemap.filter(x=> (x.position === "Button Header" || x.position === "Button Header & Footer") && x.parent !== "Tanpa Parent").map(item=>{
                                      return(
                                        <Grid item>
                                          <a href={item.link}>
                                              <Button style={{ paddingTop: 10, paddingBottom: 10, width: '100%', alignItems: 'unset', justifyContent: 'unset' }}>
                                                <Typography className={classes.txtNavbarDropDown}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN }</Typography>
                                              </Button>
                                          </a>
                                        </Grid>
                                      )
                                  })
                                }
                                </div>
                              </Paper>
                            ) : false
                          }
                          
                        </div>
                      </ClickAwayListener>
                    </Grid>
                    <Grid item md={5} style={{ position: 'relative' }}>
                      <TextField select style={{ minWidth: 0, width: '92%', textAlign:'center', fontSize:13, height: 32, borderRadius:5, backgroundColor: 'transparent', border: '1px solid #bababa' }} value={Languages} onChange={e=> {localStorage.setItem('lang',e.target.value);Languagedispatch({type:'SET_DATA', data:e.target.value})}}>
                        <MenuItem value={"Bahasa Indonesia"}>Indonesia</MenuItem>
                        <MenuItem value={"English"}>English</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Hidden>

              </Grid>


              <Grid container className={classes.containerMenu}>
                <Hidden smDown>
                  {
                    this.state.sitemap.map((item,index) => {

                      if (item.position === "Header & Footer" || item.position === "Header") {
                        return (
                          item.parent === "Tanpa Parent" ?

                            item.category === "Hanya Menu" ?
                              <Grid item style={{ position: 'relative' }} className={classes.spacing}>
                                <ClickAwayListener onClickAway={() => (this.setState([item.setDropDown = false]))}>
                                  <div>
                                    <Button className={classes.btnStyle}
                                      onClick={() => (this.setState([item.setDropDown = !item.setDropDown]))}>
                                      <Typography className={classes.txtNavbar}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN}</Typography>
                                      <ExpandMoreIcon style={{ color: '#1f313d' }} />
                                    </Button>
                                    {
                                      item.setDropDown ? (
                                        <Paper style={dropDownStyle}>
                                          {
                                            this.state.sitemap.map((val,index) => {
                                              if (val.parent !== "") {
                                                if (val.parent === item._id) {
                                                  if (val.link === "") {
                                                    return (
                                                      <Grid item key={index} onClick={() => (this.setState([item.setDropDown = !item.setDropDown]))}>
                                                        <Button component={NavLink} to={val.base_URL} style={{ paddingTop: 10, paddingBottom: 10, width: '100%', alignItems: 'unset', justifyContent: 'unset' }}>
                                                          <Typography className={classes.txtNavbarDropDown}>{Languages === "Bahasa Indonesia" ? val.title : val.titleEN }</Typography>
                                                        </Button>
                                                      </Grid>
                                                    )
                                                  }
                                                  else {
                                                    return (
                                                      <Grid item>
                                                        <a href={val.link}>
                                                          <Button style={{ paddingTop: 10, paddingBottom: 10, width: '100%', alignItems: 'unset', justifyContent: 'unset' }}>
                                                            <Typography className={classes.txtNavbarDropDown}>{Languages === "Bahasa Indonesia" ? val.title : val.titleEN }</Typography>
                                                          </Button>
                                                        </a>
                                                      </Grid>
                                                    )
                                                  }

                                                }
                                              }
                                            })
                                          }
                                        </Paper>
                                      ) : null
                                    }
                                  </div>
                                </ClickAwayListener>
                              </Grid> :

                              item.link === "" ?
                                <Grid item className={classes.spacing}>
                                  <Button component={NavLink} to={item.base_URL} className={classes.btnStyle}>
                                    {/* <img src={item.icon} alt="img" style={{width:17,height:17,marginRight:4}}/> */}
                                    <Typography className={classes.txtNavbar}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN}</Typography>
                                  </Button>
                                </Grid> :
                                <Grid item className={classes.spacing}>
                                  <a href={item.link}>
                                    <Button className={classes.btnStyle}>
                                      <Typography className={classes.txtNavbar}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN}</Typography>
                                    </Button>
                                  </a>
                                </Grid> : null


                        )
                      }
                    })
                  }

                </Hidden>

                {/* Menu Mobile */}
                <Hidden mdUp>
                  <Button style={{ position: 'absolute', right: 15, top: 10 }} onClick={() => this.openMenuMobile()}>
                    <center><DehazeIcon style={{ fontSize: 28 }} /></center>
                  </Button>
                  <Drawer
                    anchor="top"
                    open={this.state.menuMobile}
                    onClose={() => this.closeMenuMobile()}
                  >
                    <div className={classes.fullList}>
                      <div style={{ height: 60 }}>
                        <Button
                          component={NavLink} to="/"
                          className={classes.btnlogoStyle}
                        >
                          <img src={logo} alt="Logo" className={classes.imglogoStyle} />
                        </Button>
                        <Grid container>
                          <img src={backgroundTopNavImg} alt="Logo" style={{ width: '80%', height: 60 }} />

                          <Button onClick={() => this.closeMenuMobile()} style={{ position: 'absolute', right: 10, top: 10 }}>
                            <CloseIcon style={{ fontSize: 28 }} />
                          </Button>

                        </Grid>
                      </div>

                      {
                        this.state.sitemap.map((item,index) => {

                          if (item.position === "Header & Footer" || item.position === "Header") {
                            return (

                              item.parent === "Tanpa Parent" ?

                                item.category === "Hanya Menu" ?

                                  <ExpansionPanel>
                                    <ExpansionPanelSummary
                                      expandIcon={<KeyboardArrowRightIcon style={{ color: 'black' }} />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Button style={btnMenuMobile}>
                                        <Typography style={txtMenuMobile}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN}</Typography>
                                      </Button>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid container>
                                        {
                                          this.state.sitemap.map((val,index) => {
                                            if (val.parent !== "") {
                                              if (val.parent === item._id) {
                                                if (val.link === "") {
                                                  return (
                                                    <Button key={index} onClick={() => this.closeMenuMobile()} component={NavLink} to={val.base_URL} style={btnMenuMobile}>
                                                      <Typography style={txtMenuMobileExpand}>{Languages === "Bahasa Indonesia" ? val.title : val.titleEN }</Typography>
                                                    </Button>
                                                  )
                                                }
                                                else {
                                                  return (
                                                    <Button style={btnMenuMobile}>
                                                      <a href={val.link}>
                                                        <Typography style={txtMenuMobileExpand1}>{Languages === "Bahasa Indonesia" ? val.title : val.titleEN }</Typography>
                                                      </a>
                                                    </Button>
                                                  )
                                                }

                                              }
                                            }
                                          })
                                        }
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel> :

                                  item.link === "" ?

                                    <Button component={NavLink} to={item.base_URL} style={btnMenuMobile}>
                                      <Typography style={txtMenuMobile}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN}</Typography>
                                    </Button> :
                                    <a href={item.link}>
                                      <Button style={btnMenuMobile}>
                                        <Typography style={txtMenuMobile}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN}</Typography>
                                      </Button>
                                    </a>
                                : null
                            )
                          }
                        })
                      }
                      <Divider/>
                      <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<KeyboardArrowRightIcon style={{color:'black'}} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          {
                            this.state.sitemap.filter(x=> (x.position === "Button Header" || x.position === "Button Header & Footer") && x.parent === "Tanpa Parent").map(item=>{
                              return(
                                <Button style={btnMenuMobile}>
                                  <Typography style={txtMenuMobile}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN }</Typography>
                                </Button>
                              )
                            })
                          }
                          
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid container>
                            {
                              this.state.sitemap.filter(x=> (x.position === "Button Header" || x.position === "Button Header & Footer" && x.parent !== "Tanpa Parent")).map(item=>{
                                return(
                                  <Button style={btnMenuMobile}>
                                    <a href={item.link}>
                                      <Typography style={txtMenuMobileExpand1}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN }</Typography>
                                    </a>
                                  </Button>
                                )
                              })
                            }
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>

                    </div>
                  </Drawer>
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

// ===================> Style Mobile Responsive <==========================

const styles = theme => ({
  btnStyle: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
  },

  containerMenu: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 175,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 230,
    },
    flexDirection: 'row',
    height: 36,
    paddingTop: 3,
    paddingRight: 50
  },

  spacing: {
    [theme.breakpoints.down('md')]: {
      marginRight: 9
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 30
    },
  },

  txtNavbar: {
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
    },
    fontFamily: "Roboto",
    fontWeight: "bold",
    textAlign: "left",
    textTransform: 'none',
    color: "#1f313d"
  },

  txtNavbarDropDown: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 13,
    },
    fontFamily: "Roboto",
    fontWeight: "bold",
    textAlign: "left",
    textTransform: 'none',
    color: "#1f313d",
  },

  txtTiket: {
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
    },
    color: '#bf272b',
    textTransform: "none",
  },

  txtPendaftaran: {
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
    },
    color: '#ffffff',
    textTransform: "none",
    fontWeight: 200
  },

  btnlogoStyle: {
    [theme.breakpoints.down('md')]: {
      width: 140,
      height: 70,
    },
    [theme.breakpoints.up('lg')]: {
      width: 155,
      height: 80,
    },
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 40
  },

  imglogoStyle: {
    [theme.breakpoints.down('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      height: 80,
    },
    width: '100%',
    height: 80,
    position: 'absolute',
  },
  fullList: {
    height:'100%',
  },
  headerBox: {
    padding: 0,
    height: 80,
    boxShadow: "2px 2px 5px #888888",
    backgroundColor: "#ffffff",
    color: "#000000"
  }
})

const txtMenuMobile = {
  position: 'absolute',
  left: 30,
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 300
}

const txtMenuMobileExpand = {
  position: 'absolute',
  left: 45,
  textTransform: 'none',
  fontSize: 13,
  fontWeight: 200,
}

const txtMenuMobileExpand1 = {
  position: 'absolute',
  left: 45,
  textTransform: 'none',
  fontSize: 13,
  bottom:15,
  color:'#000000',
  fontWeight: 200,
}

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: 'none',
    marginBottom: -1,
    height: 30,
    paddingLeft: 0,
    '&$expanded': {
      height: 30,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
    paddingLeft: 0
  },
}))(MuiExpansionPanelDetails);

const btnMenuMobile = {
  width: '100%',
  height: 51,
}

// =================================================================

const toolbar = {
  padding: 0,
  minHeight: 0,
  backgroundColor: "#ffffff",
  color: "black"
};

const dropDownStylePendaftaran = {
  position: 'absolute',
  top: 37,
  right: 0,
  left: 0,
  zIndex: 10
}

const dropDownStyle = {
  position: 'absolute',
  top: 37,
  right: 0,
  left: 0
}

export default withStyles(styles)(Header);
