import React, {useContext, useEffect, useState} from 'react'
import NewScreen from "./News";
import { NewsContext } from "../../Context/NewsContext";
export default function NewsHook(props) {
    const {category} = props.match.params
    const {News}  = useContext(NewsContext)
    return (
        <NewScreen news={News} category={category} history={props.history} />
        // <NewScreen news={News.length > 0 ? News : JSON.parse(localStorage.news)} category={category} />
    )
}
