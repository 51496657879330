import React, {useContext, useEffect, useState} from 'react'
import GalleryPhoto from "./GalleryPhoto";
import { PhotoContext } from "../../Context/PhotoContext";
export default function GalleryPhotoHook(props) {
    const {category} = props.match.params
    const {Photo}  = useContext(PhotoContext)
    return (
        <GalleryPhoto dataFoto={Photo.length > 0 ? Photo.filter(x=>x.category === category) : JSON.parse(localStorage.photo).filter(x=>x.category === category)} category={category} />
    )
}
