import React, { Component } from "react";

// * Material UI import
import {
  Typography,
  Grid,
  Hidden,
  Button,
  withStyles
} from "@material-ui/core";

import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

import logoFooterIcon from "../../Assets/Images/logo_footer.png";
import logo from "../../Assets/Images/LOGO-2-PON-2020-Horizontal-teks-putih.png";
import KemenPemudaOlahragaIcon from "../../Assets/Images/kemenpemuda_olahraga.png";
import LogoBrilyanIcon from "../../Assets/Images/logo_brilyan.png";
import LogoPapuaIcon from "../../Assets/Images/logo_papua.png";
import KoniIcon from "../../Assets/Images/national_sports_committee_of_indonesia_koni.png";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { LanguageContext } from "../../Context/languageContext";
import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"
// import axios from "axios";
// const url = "http://localhost:301";

class Footer extends Component {
  static contextType = LanguageContext
  constructor() {
    super();
    this.state = {
      event_support: [],
      sitemap: []
    };
  }

  getDataSiteMap() {
    Api.getSitemap().then(res=>{
      this.setState({ sitemap: res.data });
    })
    // axios.get(url + "/sitemap").then(res => {
    //   this.setState({ sitemap: res.data });
    // });
  }

  // getDataLayanan() {
  //   fetch(
  //     "http://localhost:301/pages/parent/Layanan Dukungan Pertandingan"
  //   )
  //     .then(response => response.json())
  //     .then(resData => {
  //       this.setState({ event_support: resData });
  //     });
  // }

  async componentDidMount() {
    // await this.getDataLayanan();
    await this.getDataSiteMap();
  }

  render() {
    const { classes } = this.props;
    const {Languages, Languagedispatch} = this.context
    return (
      <div className={classes.containerFooter}>
        <img
          src={logoFooterIcon}
          alt="logoFooter"
          className={classes.logoFooterIconStyle}
        />

        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid container style={{ marginBottom: 45 }}>
              <Grid
                item
                md={2}
                style={{ paddingLeft: 40 }}
                className={classes.spacing}
              >
                <img
                  src={logo}
                  alt="logoFooter"
                  style={{ width: "100%", marginTop: 7, height: 70 }}
                />
                <Typography className={classes.listTxt}>
                  © PON XX Papua 2021
                </Typography>
              </Grid>

              {/* <Grid></Grid> */}
              {this.state.sitemap.map((item, index) => {
                if (item.position === "Header & Footer" || item.position === "Footer" || item.position === "Button Header & Footer") {
                  return item.parent === "Tanpa Parent" ? (
                    item.category === "Hanya Menu" ? (
                      <Grid item className={classes.spacing}>
                        <Typography className={classes.titleFooterTxt}>
                          {Languages === "Bahasa Indonesia" ? item.title : item.titleEN}
                        </Typography>

                        {this.state.sitemap.map((val, index) => {
                          if (val.parent !== "") {
                            if (val.parent === item._id) {
                              if (val.link === "") {
                                return (
                                  <Grid
                                    key={index}
                                    component={NavLink}
                                    to={val.base_URL}
                                  >
                                    <Typography className={classes.listTxt}>
                                      {Languages === "Bahasa Indonesia" ? val.title : val.titleEN}
                                    </Typography>
                                  </Grid>
                                );
                              } else {
                                return (
                                  <a href={val.link}>
                                    <Typography className={classes.listTxt}>
                                      {Languages === "Bahasa Indonesia" ? val.title : val.titleEN}
                                    </Typography>
                                  </a>
                                );
                              }
                            }
                          }
                        })}
                      </Grid>
                    ) : item.link === "" ? (
                      <Grid
                        item
                        key={index}
                        className={classes.spacing}
                        component={NavLink}
                        to={item.base_URL}
                      >
                        <Typography className={classes.titleFooterTxt}>
                          {Languages === "Bahasa Indonesia" ? item.title : item.titleEN}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item key={index} className={classes.spacing}>
                        <a href={item.link}>
                          <Typography className={classes.titleFooterTxt}>
                            {Languages === "Bahasa Indonesia" ? item.title : item.titleEN}
                          </Typography>
                        </a>
                      </Grid>
                    )
                  ) : null;
                }
                
              })}
            </Grid>
            <Grid item lg={12} style={{ paddingLeft: 40 }}>
            <Typography
                  style={{ fontSize: 13, fontWeight: 300, color: "#ffffff" }}
                >
                  {Languages === "Bahasa Indonesia" ? "Ikuti kami di" : "Follow us at"}
                </Typography>
            </Grid>
            <Grid item lg={12} style={{ paddingLeft: 40 }}>
              <div>
                <FacebookIcon
                  style={{ fontSize: 25, color: "white", marginRight: 20 }}
                />
                <InstagramIcon
                  style={{ fontSize: 25, color: "white", marginRight: 20 }}
                />
                <YouTubeIcon
                  style={{ fontSize: 25, color: "white", marginRight: 20 }}
                />
                <TwitterIcon
                  style={{ fontSize: 25, color: "white", marginRight: 20 }}
                />
              </div>
            </Grid>
            <Grid item md={4} style={{ paddingLeft: 40 }}>
              <img
                src={KemenPemudaOlahragaIcon}
                alt="iconolahraga"
                style={{ width: 60, height: 50, marginRight: 20 }}
              />
              <img
                src={KoniIcon}
                alt="iconkoni"
                style={{ width: 50, height: 50, marginRight: 20 }}
              />
              <img
                src={LogoPapuaIcon}
                alt="logopapua"
                style={{ width: 50, height: 50, marginRight: 20 }}
              />
              {/* <img
                src={LogoBrilyanIcon}
                alt="logobrilyan"
                style={{ width: 90, height: 40, marginRight: 20 }}
              /> */}
            </Grid>
          </Hidden>

          {/* Menu Mobile */}

          <Hidden mdUp>
            <Grid
              container
              style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 10 }}
            >
              <Grid item xs={12} style={{ paddingLeft: 40 }}>
                <img alt="" src={logo} style={{ width: 170, height: 70 }} />
                <Typography className={classes.listTxt}>
                  © PON XX Papua 2020
                </Typography>
              </Grid>
              {this.state.sitemap.map((item, index) => {
                if (item.position === "Header & Footer" || item.position === "Footer" || item.position === "Button Header & Footer") {
                  return item.parent === "Tanpa Parent" ? (
                    item.category === "Hanya Menu" ? (
                      <Grid item xs={12}>
                        <ExpansionPanel>
                          <ExpansionPanelSummary
                            expandIcon={
                              <KeyboardArrowRightIcon
                                style={{ color: "white" }}
                              />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              className={classes.txtMenuMobileExpandSummary}
                            >
                              {Languages === "Bahasa Indonesia" ? item.title : item.titleEN}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid container>
                              {this.state.sitemap.map((val, index) => {
                                if (val.parent !== "") {
                                  if (val.parent === item._id) {
                                    if (val.link === "") {
                                      return (
                                        <Button
                                          key={index}
                                          style={btnMenuMobile}
                                          component={NavLink}
                                          to={val.base_URL}
                                        >
                                          <Typography
                                            className={
                                              classes.txtMenuMobileExpandDetail
                                            }
                                          >
                                            {Languages === "Bahasa Indonesia" ? val.title : val.titleEN}
                                          </Typography>
                                        </Button>
                                      );
                                    } else {
                                      return (
                                        <Grid item xs={12} key={index}>
                                          <a href={val.link}>
                                            <Button style={btnMenuMobile}>
                                              <Typography
                                                className={
                                                  classes.txtMenuMobileExpandDetail
                                                }
                                              >
                                                {Languages === "Bahasa Indonesia" ? val.title : val.titleEN}
                                              </Typography>
                                            </Button>
                                          </a>
                                        </Grid>
                                      );
                                    }
                                  }
                                }
                              })}
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                    ) : item.link === "" ? (
                      <Grid item xs={12} key={index}>
                        <Button
                          className={classes.btnMenuMobile}
                          component={NavLink}
                          to={item.base_URL}
                        >
                          <Typography className={classes.txtMenuMobile}>
                            {Languages === "Bahasa Indonesia" ? item.title : item.titleEN}
                          </Typography>
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={12} key={index}>
                        <a href={item.link}>
                          <Button className={classes.btnMenuMobile}>
                            <Typography className={classes.txtMenuMobile}>
                              {Languages === "Bahasa Indonesia" ? item.title : item.titleEN}
                            </Typography>
                          </Button>
                        </a>
                      </Grid>
                    )
                  ) : null;
                } 
              })}

            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={
                    <KeyboardArrowRightIcon
                      style={{ color: "white" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.txtMenuMobileExpandSummary}>
                    Pendaftaran dan Akreditasi
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      <a href="https://entries.ponxx2020papua.com">
                        <Button style={btnMenuMobile}>
                          <Typography
                            className={classes.txtMenuMobileExpandDetail}
                          >
                            Kontingen
                          </Typography>
                        </Button>
                      </a>
                      <a href="https://accreditation.ponxx2020papua.com">
                        <Button style={btnMenuMobile}>
                          <Typography
                            className={classes.txtMenuMobileExpandDetail}
                          >
                            Akreditasi
                          </Typography>
                        </Button>
                      </a>
                      <a href="https://sports.ponxx2020papua.com">
                        <Button style={btnMenuMobile}>
                          <Typography
                            className={classes.txtMenuMobileExpandDetail}
                          >
                            Disiplin Olahraga
                          </Typography>
                        </Button>
                      </a>
                      <a href="https://volunteer.ponxx2020papua.com">
                        <Button style={btnMenuMobile}>
                          <Typography
                            className={classes.txtMenuMobileExpandDetail}
                          >
                            Volunteer
                          </Typography>
                        </Button>
                      </a>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              </Grid>
              {/* <Grid item xs={12}/> */}
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography
                  style={{ fontSize: 13, fontWeight: 300, color: "#ffffff" }}
                >
                  {Languages === "Bahasa Indonesia" ? "Ikuti kami di" : "Follow us at"}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ zIndex: 10 }}>
                <div>
                  <FacebookIcon className={classes.iconFollowUs} />
                  <InstagramIcon className={classes.iconFollowUs} />
                  <YouTubeIcon className={classes.iconFollowUs} />
                  <TwitterIcon className={classes.iconFollowUs} />
                </div>
              </Grid>
              <Grid item xs={12} style={{ zIndex: 10 }}>
                <img
                  src={KemenPemudaOlahragaIcon}
                  alt="iconolahraga"
                  style={{ width: 30, height: 30, marginRight: 15 }}
                />
                <img
                  src={KoniIcon}
                  alt="iconkoni"
                  style={{ width: 30, height: 30, marginRight: 15 }}
                />
                <img
                  src={LogoPapuaIcon}
                  alt="logopapua"
                  style={{ width: 30, height: 30, marginRight: 15 }}
                />
                {/* <img
                  src={LogoBrilyanIcon}
                  alt="logobrilyan"
                  style={{
                    marginTop: 20,
                    width: 60,
                    height: 30,
                    marginRight: 15
                  }}
                /> */}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

// ====================> Style Mobile Responsive <==========================

const styles = theme => ({
  titleFooterTxt: {
    [theme.breakpoints.down("md")]: {
      fontSize: 15
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 16
    },
    color: "#ffffff",
    fontWeight: 200,
    marginBottom: 18
  },

  listTxt: {
    [theme.breakpoints.down("md")]: {
      fontSize: 13
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 14
    },
    color: "#ffffff",
    fontWeight: 100,
    marginBottom: 20
  },

  spacing: {
    [theme.breakpoints.down("md")]: {
      marginRight: 30
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: 40
    }
  },

  spacing1: {
    [theme.breakpoints.down("md")]: {
      marginRight: 0
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: 40
    }
  },

  spacing2: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 40
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 0
    }
  },

  btnMenuMobile: {
    [theme.breakpoints.down("xs")]: {
      height: 35
    },
    [theme.breakpoints.up("sm")]: {
      height: 35
    },
    width: "100%",
    zIndex: 10,
    borderBottom: "2px solid #ffffff",
    borderRadius: 0,
    height: 47
  },
  txtMenuMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 15
    },
    position: "absolute",
    left: 0,
    textTransform: "none",
    fontWeight: 300,
    color: "#ffffff"
  },

  txtMenuMobileExpandSummary: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 15
    },
    position: "absolute",
    textTransform: "none",
    fontSize: 15,
    fontWeight: 300,
    color: "#ffffff",
    bottom: 10
  },

  txtMenuMobileExpandDetail: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 14
    },
    position: "absolute",
    left: 0,
    textTransform: "none",
    fontWeight: 200,
    color: "#ffffff"
  },

  logoFooterIconStyle: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 130,
      width: 155,
      height: 270
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 65,
      width: 220,
      height: 360
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      width: 350,
      height: 465
    },
    right: 0,
    zIndex: 1,
    position: "absolute"
  },
  iconFollowUs: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 20
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 25
    },
    color: "white",
    marginRight: 20,
    zIndex: 10
  },
  containerFooter: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: 25,
      paddingBottom: 25
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 40,
      paddingBottom: 40
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 80,
      paddingBottom: 50
    },
    backgroundImage: "linear-gradient(to right, #bf272b , #601416 )",
    paddingLeft: 24,
    paddingRight: 24
  }
});

const ExpansionPanel = withStyles({
  root: {
    zIndex: 10,
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: "2px solid #ffffff",
    height: 35,
    padding: 0,
    minHeight: 0,
    "&$expanded": {
      height: 35,
      minHeight: 0
    }
  },
  content: {
    "&$expanded": {
      margin: "0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const btnMenuMobile = {
  width: "100%",
  height: 35
};

export default withStyles(styles)(Footer);
