import React, {useContext} from 'react'
import NewsDetail from "./NewsDetail"
import { NewsContext } from "../../Context/NewsContext";
export default function NewsDetailHook(props) {
    const {title, category} = props.match.params
    const {News}  = useContext(NewsContext)
    return (
        <NewsDetail history={props.history} 
            content={News.length > 0 ? News.filter(x=>x.category === category && x.title === title)[0] : JSON.parse(localStorage.news).filter(x=>x.category === category && x.title === title)[0]} 
            news={News.length > 0 ? News : JSON.parse(localStorage.news)} title={title} category={category} />
    )
}
