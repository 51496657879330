// const BASE_URL = 'https://apidashboard.ponxx2020papua.com' //
const BASE_URL = 'https://api.ponxx2020papua.com' //
const BASE_URL_GAMES = 'https://apigames.ponxx2020papua.com' //
//Base Url API

//http://localhost:5000
//http://147.139.163.103:5000

export default {
  Base_Url : BASE_URL,
  Base_Url_Games : BASE_URL_GAMES,
  ImageUrl: `${BASE_URL}/repo/`
}