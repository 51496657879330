import React, { useEffect, useContext} from 'react'
import Home from './Home';
import { NewsContext } from "../../Context/NewsContext";
import { HighligthContext } from "../../Context/HighligthContext";
import { VideoContext } from "../../Context/VideoContext";
import { PhotoContext } from "../../Context/PhotoContext";
import { SponsorContext } from "../../Context/SponsorContext";
import _ from "lodash";

export default function HomeHooks() {
    const {News}  = useContext(NewsContext)
    const {Highligth}  = useContext(HighligthContext)
    const {Video}  = useContext(VideoContext)
    const {Photo}  = useContext(PhotoContext)
    const {Sponsor}  = useContext(SponsorContext)
    return (
        <Home news={_.take(News, 8)} highlight={Highligth} video={_.take(Video, 2)} photo={_.take(Photo, 6)} sponsor={Sponsor} />
    )
}
