import React, { Component, Fragment } from "react";
import _ from "lodash";
import ContentLoader from 'react-content-loader'

import {
  Button,
  Hidden,
  Fab,
  CssBaseline,
  Grid,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import moment from "moment";

import PropTypes from "prop-types";
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
// *
import ponTaglineImg from "../../Assets/Images/pon_tagline.png";
import subtractionImg from "../../Assets/Images/subtraction_1.png";
import maskotDrawaImg from "../../Assets/Images/maskot_drawa_1.png";
import kangpoImg from "../../Assets/Images/kangpo.png";
import AbstractImg from "../../Assets/Images/background_abstract.png";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import "../../../node_modules/video-react/dist/video-react.css";


import { Player, PosterImage } from "video-react";
import { NavLink } from "react-router-dom";

import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"


import { LanguageContext } from "../../Context/languageContext";
import { NewsContext } from "../../Context/NewsContext";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const urlgames = StaticVar.Base_Url_Games;

class Home extends Component {
  static contextType = LanguageContext
  constructor() {
    super();
    this.state = {
      // news: [],
      video: [],
      photo: [],
      events: [],
      // highlight: [],
      sponsor: [],
      dataHighlight: [
        {
          title: "PAPUA SIAP",
          image: require("../../Assets/Images/STADION-PAPUA-BANGKIT-2.jpg")
        },
        {
          title: "PAPUA SIAP",
          image: require("../../Assets/Images/STADION-PAPUA-BANGKIT-2.jpg")
        },
        {
          title: "PAPUA SIAP",
          image: require("../../Assets/Images/STADION-PAPUA-BANGKIT-2.jpg")
        }
      ],
      activeStep: 0,
      time: new Date("Oct 2, 2021 20:20:00").getTime(),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      loadingHighlight: false,
      loadingNews:true,
      loadingVideo:true,
      loadingPhoto:true,
      loadingSponsor:true,
    };

    this.countdown = setInterval(() => {
      var _time = this.state.time;
      this.setState({ time: _time - 1 });
      var now = new Date().getTime();
      var distance = _time - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.setState({
        days,
        hours,
        minutes,
        seconds
      });

      // console.log('Interval triggered',_time, days,hours,minutes,seconds);
    }, 1000);
  }
  async componentDidMount() {
    console.log("component did mount");
    
    // const { Languages, Languagedispatch} = this.context
    // const { News, Newsdispatch} = this.context
    // console.log('Languages', Languages)
    // console.log('News', News)
    // Api.getNews().then(resData=>{
    //   const result = _.take(resData.data, 8);
    //   this.setState({ news: result });
    // })

    // Api.getVideos().then(resData=>{
    //   const result = _.take(resData.data, 2);
    //   this.setState({ video: result });
    // })

    // Api.getSponsor().then(resData=>{
    //   this.setState({ sponsor: resData.data });
    // })

    // Api.getHighligth().then(resData=>{
    //   const result = _.filter(resData.data, ["status", "Publish"]);
    //   this.setState({ highlight: result });
    //   this.setState({ loadingHighlight: false })
    // }).catch(err=>{
    //   console.log("err",err)
    //   this.setState({ loadingHighlight: false })
    // });

    // Api.getPhotos().then(resData=>{
    //   const result = _.take(resData.data, 6);
    //   this.setState({ photo: result });
    // })

    await fetch(urlgames + "/events")
      .then(response => response.json())
      .then(resData => {
        this.setState({ events: resData });
      });
  }

  componentWillUnmount() {
    clearInterval(this.countdown);
  }

  handleNext() {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  }

  handleBack() {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  }

  handleStepChange(step) {
    this.setState({ activeStep: step });
  }

  render() {
    const { classes } = this.props;
    const maxSteps = this.props.highlight.length;
    const {Languages, Languagedispatch} = this.context
    return (
      <Fragment>
        <CssBaseline />
        <Header />

        {/* menu unit */}
        <main
          style={{
            backgroundImage: "linear-gradient(to right, #ffffff , #c4c4c4 )",
            height: "100%"
          }}
        >
          <div className={classes.container}>
            
          <Hidden xsDown>
              {
                this.state.loadingHighlight ? 
              <div style={{backgroundColor:'#fff',height:580,paddingTop:170}}>
              <ContentLoader
                speed={3}
                width={"100%"}
                height={500}
                viewBox="0 0 400 160"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                <circle cx="20" cy="20" r="20" />
              </ContentLoader>
              </div>
                : <>    
                <AutoPlaySwipeableViews
                style={{ marginTop: 80 }}
                index={this.state.activeStep}
                onChangeIndex={e => this.handleStepChange(e)}
                enableMouseEvents
              >
                {this.props.highlight.map((item, index) => (
                  <div key={item._id} style={{ height: 500 }}>
                    {
                      <div
                        style={{
                          paddingTop: 100,
                          backgroundImage: `url("${StaticVar.ImageUrl +
                            item.picture}")`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "100%",
                          height: "auto",
                          minHeight: "100%",
                          width: "100%",
                          backgroundPosition: "center"
                        }}
                      >
                        <div
                          style={{
                            width: 350,
                            paddingBottom: 50,
                            paddingLeft: 20,
                            paddingTop: 20,
                            paddingRight: 20,
                            borderRadius: 15,
                            backgroundColor: "#ededed",
                            opacity: 0.7,
                            marginLeft: 150,
                            zIndex: 10
                          }}
                        >
                          {/* <Typography style={{ fontSize: 51, fontWeight: 'bold', margin: 0, padding: 0, lineHeight: 0.9 }}>{item.title}</Typography> */}
                          <Typography
                            style={{
                              fontSize: 26,
                              margin: 0,
                              padding: 0,
                              lineHeight: 1.1,
                              fontWeight: "bold",
                              marginTop: 10
                            }}
                          >
                            {Languages === "Bahasa Indonesia" ? item.title : item.titleEN}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 15,
                              marginTop: 7,
                              marginBottom: 7
                            }}
                          >
                            {Languages === "Bahasa Indonesia" ? item.content : item.contentEN}
                          </Typography>
                          <Button
                            component={NavLink}
                            to="/news"
                            style={{
                              width: 120,
                              height: 30,
                              padding: 0,
                              backgroundColor: "#f58634",
                              float: "right"
                            }}
                          >
                            <Typography
                              style={{
                                textTransform: "none",
                                color: "#ffffff",
                                fontWeight: 200
                              }}
                            >
                              {
                                Languages === "Bahasa Indonesia" ? "Selengkapnya" : "More detail"
                              }
                              
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    }
                  </div>
                ))}
              </AutoPlaySwipeableViews>
                <Fab
                  style={{
                    width: 50,
                    height: 50,
                    paddingLeft: 4,
                    backgroundColor: "#f58634",
                    position: "absolute",
                    top: 270,
                    left: 20
                  }}
                  onClick={() => this.handleBack()}
                  disabled={this.state.activeStep === 0}
                >
                  <ArrowBackIosIcon
                    style={{ color: "#ffffff", fontSize: 18, fontWeight: "bold" }}
                  />
                </Fab>
                <Fab
                style={{
                  width: 50,
                  height: 50,
                  backgroundColor: "#f58634",
                  position: "absolute",
                  top: 270,
                  right: 20
                }}
                onClick={() => this.handleNext()}
                disabled={this.state.activeStep === maxSteps - 1}
              >
                <ArrowForwardIosIcon
                  style={{ color: "#ffffff", fontSize: 18, fontWeight: "bold" }}
                />
              </Fab>
                </>
              }
              

            </Hidden>

            <Hidden smUp>
              <AutoPlaySwipeableViews
                style={{ marginTop: 80 }}
                enableMouseEvents
              >
                {this.props.highlight.map((item, index) => {
                  // var str = item.content;
                  // var slc = str.slice(0,160);
                  // var content = slc.concat(' ');
                  return (
                    <div key={index}>
                      {
                        <div
                          style={{
                            height: 320,
                            backgroundImage: `url("${StaticVar.ImageUrl +
                              item.picture}")`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%"
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#363636",
                              position: "absolute",
                              bottom: 0,
                              opacity: 0.7,
                              zIndex: 10,
                              paddingLeft: 11,
                              paddingRight: 11,
                              paddingTop: 9,
                              paddingBottom: 9
                            }}
                          >
                            <Typography
                              rowsMax={1}
                              style={{
                                fontSize: 16,
                                fontWeight: 300,
                                margin: 0,
                                padding: 0,
                                lineHeight: 1,
                                color: "#ffffff"
                              }}
                            >
                              {Languages === "Bahasa Indonesia"? item.title : item.titleEN }
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 13,
                                fontWeight: 200,
                                marginTop: 5,
                                color: "#ffffff",
                                lineHeight: 1.1
                              }}
                            >
                              {Languages === "Bahasa Indonesia"?  item.content : item.contentEN }
                            </Typography>
                            {/* <Button component={NavLink} to="/news" style={{ width: 100, height: 30, padding: 0, backgroundColor: '#f58634', float: 'right',marginRight:7,marginTop:3 }}><Typography style={{ fontSize:15,textTransform: 'none', color: '#ffffff', fontWeight: 200 }}>Selengkapnya</Typography></Button> */}
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </AutoPlaySwipeableViews>
            </Hidden>


            <div className={classes.containerStatus}>
              <img
                alt="img"
                src={subtractionImg}
                className={classes.subtractionImg}
              />

              <Grid container>
                <Hidden xsDown>
                  <Grid item md={3} sm={4}>
                    <img
                      alt="img"
                      src={ponTaglineImg}
                      className={classes.taglineImg}
                    />
                  </Grid>

                  <Grid item md={6} sm={8} className={classes.containerTime}>
                    <Typography className={classes.txtTitleStatus}>
                      {
                        Languages === "Bahasa Indonesia" ? "MENUJU PON PAPUA 2021 . 2 OKT 2021 20:20 WIT" : "ROAD TO PON PAPUA 2021 . 2 OCT 2021 20:20 WIT"
                        } 
                    </Typography>
                    <Grid container>
                      <Grid item style={{ width: "20%" }}>
                        <Typography className={classes.txtTime}>
                          {this.state.days}{" "}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center"
                          }}
                        >
                          {Languages === "Bahasa Indonesia"? "Hari" : "Days"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: "6%" }}>
                        <Typography className={classes.txtTime}>:</Typography>
                      </Grid>
                      <Grid item style={{ width: "20%" }}>
                        <Typography className={classes.txtTime}>
                          {this.state.hours < 10
                            ? "0" + this.state.hours
                            : this.state.hours}{" "}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center"
                          }}
                        >
                          {Languages === "Bahasa Indonesia"? "Jam" : "Hours"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: "6%" }}>
                        <Typography className={classes.txtTime}>:</Typography>
                      </Grid>
                      <Grid item style={{ width: "20%" }}>
                        <Typography className={classes.txtTime}>
                          {this.state.minutes < 10
                            ? "0" + this.state.minutes
                            : this.state.minutes}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center"
                          }}
                        >
                          {Languages === "Bahasa Indonesia"? "Menit" : "Minutes"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: "7%" }}>
                        <Typography className={classes.txtTime}>:</Typography>
                      </Grid>
                      <Grid item style={{ width: "20%" }}>
                        <Typography className={classes.txtTime}>
                          {this.state.seconds < 10
                            ? "0" + this.state.seconds
                            : this.state.seconds}{" "}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center"
                          }}
                        >
                          {Languages === "Bahasa Indonesia"? "Detik" : "Seconds"}
                          
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} sm={12}>
                    <Grid container>
                      <Grid item md={6} sm={3} className={classes.boxKategori1}>
                        <Typography className={classes.statusTitleTxt}>
                          {
                            Languages === "Bahasa Indonesia" ? "CABANG OLAHRAGA" : "SPORTS"
                          }
                        </Typography>
                        <Typography style={statusNumberTxt}>37</Typography>
                      </Grid>
                      <Grid item md={6} sm={3} className={classes.boxKategori1}>
                        <Typography className={classes.statusTitleTxt}>
                          {
                            Languages === "Bahasa Indonesia" ? "DISIPLIN" : "DISCIPLINES"
                          }
                        </Typography>
                        <Typography style={statusNumberTxt}>56</Typography>
                      </Grid>
                      <Grid item md={6} sm={3} className={classes.boxKategori2}>
                        <Typography className={classes.statusTitleTxt}>
                          {
                            Languages === "Bahasa Indonesia" ? "NO.PERTANDINGAN" : "EVENTS"
                          }
                        </Typography>
                        <Typography style={statusNumberTxt}>679</Typography>
                      </Grid>
                      <Grid item md={6} sm={3} className={classes.boxKategori3}>
                        <Typography className={classes.statusTitleTxt}>
                          {
                            Languages === "Bahasa Indonesia" ? "ATLET" : "ATHLETES"
                          }
                          
                        </Typography>
                        <Typography style={statusNumberTxt}>6442</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>

                <Hidden smUp>
                  <Grid item xs={12}>
                    <center>
                      <img
                        alt="img"
                        src={ponTaglineImg}
                        className={classes.taglineImg}
                      />
                    </center>
                  </Grid>
                  <Grid item xs={12} className={classes.containerTime}>
                    <Typography className={classes.txtTitleStatus}>
                      {
                        Languages === "Bahasa Indonesia" ? "MENUJU PON PAPUA 2021 . 20 OKT 2021 20:20 WIT" : "ROAD TO PON PAPUA 2021 . 20 OCT 2021 20:20 WIT"
                      }
                    </Typography>
                    <Grid container>
                      <Grid item style={{ width: "20%" }}>
                        <Typography className={classes.txtTime}>
                          {this.state.days}{" "}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center"
                          }}
                        >
                          {Languages === "Bahasa Indonesia"? "Hari" : "Days"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: "6%" }}>
                        <Typography className={classes.txtTime}>:</Typography>
                      </Grid>
                      <Grid item style={{ width: "20%" }}>
                        <Typography className={classes.txtTime}>
                          {this.state.hours < 10
                            ? "0" + this.state.hours
                            : this.state.hours}{" "}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center"
                          }}
                        >
                          {Languages === "Bahasa Indonesia"? "Jam" : "Hours"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: "6%" }}>
                        <Typography className={classes.txtTime}>:</Typography>
                      </Grid>
                      <Grid item style={{ width: "20%" }}>
                        <Typography className={classes.txtTime}>
                          {this.state.minutes < 10
                            ? "0" + this.state.minutes
                            : this.state.minutes}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center"
                          }}
                        >
                          {Languages === "Bahasa Indonesia"? "Menit" : "Minutes"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: "7%" }}>
                        <Typography className={classes.txtTime}>:</Typography>
                      </Grid>
                      <Grid item style={{ width: "20%" }}>
                        <Typography className={classes.txtTime}>
                          {this.state.seconds < 10
                            ? "0" + this.state.seconds
                            : this.state.seconds}{" "}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center"
                          }}
                        >
                          {Languages === "Bahasa Indonesia"? "Detik" : "Seconds"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid
                        item
                        sm={6}
                        xs={6}
                        style={{
                          paddingTop: 10,
                          height: 66,
                          borderRight: "1px solid #ffffff",
                          borderBottom: "1px solid #ffffff"
                        }}
                      >
                        <Typography className={classes.statusTitleTxt}>
                        {
                        Languages === "Bahasa Indonesia" ? "CABANG OLAHRAGA" : "SPORTS"
                        } 
                        </Typography>
                        <Typography style={statusNumberTxt}>37</Typography>
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        xs={6}
                        style={{
                          paddingTop: 10,
                          height: 66,
                          borderBottom: "1px solid #ffffff"
                        }}
                      >
                        <Typography className={classes.statusTitleTxt}>
                        {
                        Languages === "Bahasa Indonesia" ? "DISIPLIN" : "DISCIPLINES"
                        } 
                        </Typography>
                        <Typography style={statusNumberTxt}>56</Typography>
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        xs={6}
                        style={{
                          paddingTop: 10,
                          height: 66,
                          borderRight: "1px solid #ffffff"
                        }}
                      >
                        <Typography className={classes.statusTitleTxt}>
                        {
                        Languages === "Bahasa Indonesia" ? "NOMOR PERTANDINGAN" : "EVENTS"
                        } 
                        </Typography>
                        <Typography style={statusNumberTxt}>679</Typography>
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        xs={6}
                        style={{ paddingTop: 10, height: 66 }}
                      >
                        <Typography className={classes.statusTitleTxt}>
                        {
                        Languages === "Bahasa Indonesia" ? "ATLET" : "ATHLETES"
                        } 
                        </Typography>
                        <Typography style={statusNumberTxt}>6442</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </div>
            {/* Section Berita */}

            <Grid container className={classes.containerResponsive}>
              <Grid item xs={12}>
                <Typography className={classes.titleTxt}>
                {
                        Languages === "Bahasa Indonesia" ? "BERITA" : "NEWS"
                        } 
                </Typography>
                <Grid container spacing={2} className={classes.spacingTop}>
                  {this.props.news.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        component={NavLink}
                        to={
                          "/news/news-detail/" +
                          item.category +
                          "/" +
                          item.title +
                          "/#"
                        }
                        key={index}
                      >
                        <div className={classes.containerImgBerita}>
                          {/* <div className={classes.imgBeritaResponsive}> */}
                          {/* <div
                            style={{
                              borderRadius: 8,
                              backgroundImage: `url("${url +
                                "/repo/" +
                                item.picture}")`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "100%",
                              height: "auto",
                              minHeight: "100%",
                              width: "100%",
                              backgroundPosition: "center"
                            }}
                          /> */}
                          <img
                            src={StaticVar.ImageUrl + item.picture}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: 170
                            }}
                          />
                          {/* </div> */}
                          <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography
                                  style={{
                                    fontSize: 10,
                                    fontWeight: 100,
                                    color: "#000"
                                  }}
                                >
                                  {moment(item.create_date).format(
                                    "DD MMMM YYYY"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  style={{
                                    fontSize: 10,
                                    fontWeight: 100,
                                    color: "#000",
                                    textAlign: "right"
                                  }}
                                >
                                  {item.creator}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography className={classes.txtBerita}>
                            {Languages === "Bahasa Indonesia"? item.title : item.titleEN }
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button component={NavLink} to={"/news"} style={{backgroundColor:'#bf272b',padding:5,textTransform:'none',float:'right',minHeight:0,height:25}}>
                      <p style={{color:'#fff',fontSize:12}}>
                        {
                          Languages === "Bahasa Indonesia" ? "Lainnya" : "Mores"
                        }
                      </p><DoubleArrowIcon style={{color:"#fff",fontSize:14}}/>
                    </Button>
                </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Section Video and Photo*/}

            <Grid
              container
              style={{ paddingTop: 0 }}
              className={classes.containerResponsive}
            >
              <Grid item xs={12} sm={6} style={{ marginTop: 20 }}>
                <Grid container spacing={2} className={classes.containerVideo}>
                  <Grid item xs={12}>
                    <Typography className={classes.titleTxt}>VIDEO</Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                      {this.props.video.map((item, index) => {
                        return (
                          <Grid
                            item
                            key={index}
                            xs={6}
                            component={NavLink}
                            to={
                              "/video/video-detail/" +
                              item.category +
                              "/" +
                              item.title +
                              "/#"
                            }
                          >
                            <Player
                              poster={StaticVar.ImageUrl+ item.thumbnail}
                              fluid={true}
                            >
                              {/* <PlayToggle position="center" />
                            <LoadingSpinner /> */}
                            </Player>
                            <Typography
                              style={{ marginTop: 10 }}
                              className={classes.txtBerita}
                            >
                              {Languages === "Bahasa Indonesia"? item.title : item.titleEN }
                            </Typography>
                            {/* <div style={{zIndex:1,position:'relative',bottom:67,paddingRight:15,height:67,backgroundColor:'#242424',width:'100%',opacity:0.5}}>
                            
                            </div>
                            <div style={{zIndex:10,position:'relative',bottom:130,paddingLeft:10,height:67,width:'100%'}}>
                              
                            </div> */}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: 20 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.titleTxt}>
                    {
                        Languages === "Bahasa Indonesia" ? "FOTO" : "PHOTOS"
                        } 
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: 9 }}>
                    {this.props.photo.map((item, index) => {
                      return (
                        <Grid
                          item
                          component={NavLink}
                          to={"/photos/category/" + item.category}
                          key={index}
                          md={4}
                          sm={4}
                          xs={6}
                          className={classes.containerPhoto}
                        >
                          <div className={classes.imgPhotoResponsive}>
                            <div
                              style={{
                                borderRadius: 8,
                                backgroundImage: `url("${StaticVar.ImageUrl +
                                  item.picture}")`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100%",
                                height: 110,
                                width: "100%",
                                backgroundPosition: "center",
                                paddingTop: 80
                              }}
                            >
                              <div className={classes.backgroundTxtPhoto}>
                                <Typography className={classes.txtPhoto}>
                                {Languages === "Bahasa Indonesia"? item.title : item.titleEN }
                                </Typography>
                              </div>
                            </div>
                          </div>

                          {/* <Typography style={{ fontSize: 10, fontWeight: 100, color: '#000000' }}>{item.create_date}</Typography> */}
                        </Grid>
                      );
                    })
                    // this.state.dataPhoto.map((item,index)=>(
                    //     <Grid item key={index} xs={4}>
                    //       <img alt="img" src={item.image} style={{width:'100%',height:100,borderRadius:5}}/>
                    //     </Grid>
                    //   )
                    // )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Section Maskot dan Piktogram */}

            <Grid
              container
              style={{ marginTop: 40 }}
              className={classes.containerResponsive}
            >
              <Grid item xs={12} sm={4} className={classes.containerMaskot}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        style={{ marginBottom: 20 }}
                      >
                        <Typography className={classes.titleTxt}>
                          {Languages === "Bahasa Indonesia"? "MASKOT" : "MASCOT"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        sm={8}
                        md={8}
                        style={{
                          marginTop: 10,
                          height: 2,
                          backgroundColor: "#7a7a7a"
                        }}
                      ></Grid>
                      <Grid item sm={1} md={1} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <center>
                          <img
                            alt="img"
                            src={maskotDrawaImg}
                            className={classes.imgMaskot}
                          />
                        </center>
                      </Grid>
                      <Grid item xs={6}>
                        <center>
                          <img
                            alt="img"
                            src={kangpoImg}
                            className={classes.imgMaskot}
                          />
                        </center>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          style={{ textAlign: "center", fontSize: 22 }}
                        >
                          Drawa
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            fontSize: 15,
                            fontWeight: 100
                          }}
                        >
                          Burung Cendrawasih
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{ textAlign: "center", fontSize: 22 }}
                        >
                          Kangpho
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            fontSize: 15,
                            fontWeight: 100
                          }}
                        >
                          Kangguru Pohon
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      sm={2}
                      md={2}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography className={classes.titleTxt}>
                      {Languages === "Bahasa Indonesia"? "PIKTOGRAM" : "PICTOGRAM"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sm={10}
                      md={10}
                      style={{
                        marginTop: 10,
                        height: 2,
                        backgroundColor: "#7a7a7a"
                      }}
                    ></Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      className={classes.containerPik}
                    >
                      {this.state.events.map(item => (
                        <Grid item xs={2} sm={1}>
                          <center>
                            <img
                              alt="img"
                              src={urlgames + "/repo/sport/" + item.event_icon}
                              className={classes.imgEventIcon}
                            />
                          </center>
                          <Typography className={classes.eventTxt}>
                            {item.event_name}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Section Sponsor */}

            <Grid container className={classes.containerSponsor}>
              <Grid item xs={3} sm={1}>
                <Typography className={classes.titleTxt}>SPONSOR</Typography>
              </Grid>
              <Grid item xs={9} sm={11}>
                <div
                  style={{
                    marginTop: 10,
                    width: "100%",
                    height: 2,
                    backgroundColor: "#7a7a7a"
                  }}
                ></div>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={12}> */}
              <Grid container>
                {/* <center> */}
                {/* <img alt="img" src={kemenPemudaOlahragaImg} className={classes.imgSponsor} /> */}
                {/* <img alt="img" src={NationalSportsCommitteeOfIndonesiaKoniImg} className={classes.imgSponsor} />
                  <img alt="img" src={LogoPapuaIcon} className={classes.imgSponsor} /> */}
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    {this.props.sponsor.map((item, index) => {
                      if (item.link == "") {
                        return (
                          <Grid
                            item
                            component={NavLink}
                            to={item.base_URL}
                            key={index}
                            className={classes.containerImgSponsor}
                          >
                            <img
                              src={StaticVar.ImageUrl + item.logo}
                              className={classes.sponsorImgStyle}
                            />
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid
                            item
                            key={index}
                            className={classes.containerImgSponsor}
                          >
                            <a href={item.link}>
                              <img
                                src={StaticVar.ImageUrl + item.logo}
                                className={classes.sponsorImgStyle}
                              />
                            </a>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={3} />
                {/* </center> */}
              </Grid>
              {/* </Grid> */}
            </Grid>
          </div>
        </main>
        {/* Footer */}
        <footer>
          <Footer />
        </footer>
        {/* End footer */}
      </Fragment>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

PosterImage.propTypes = {
  poster: PropTypes.string
};

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      background: "none"
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%"
    }
  },
  containerPhoto: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0
      // width: '90%',
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 10
      // width: '95%',
    }
  },
  containerImgBerita: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      height: 260
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 15,
      height: 255
    },
    marginBottom: 15,
    border: "1px solid #b5b5b5",
    borderRadius: 4
  },
  imgEventIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 30
    },
    [theme.breakpoints.up("md")]: {
      width: 50
    }
  },
  subtractionImg: {
    [theme.breakpoints.down("sm")]: {
      height: 80,
      width: 100
    },
    [theme.breakpoints.up("md")]: {
      height: 117,
      width: 140
    },
    position: "absolute",
    zIndex: 10
  },

  containerImgSponsor: {
    marginBottom: 10
  },
  containerPik: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 10
    }
  },
  backgroundTxtPhoto: {
    height: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    zIndex: 2,
    width: "100%",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingTop: 5,
    paddingBottom: 5
  },
  sponsorImgStyle: {
    [theme.breakpoints.down("sm")]: {
      height: 70
    },
    [theme.breakpoints.up("sm")]: {
      height: 100
    },
    [theme.breakpoints.up("md")]: {
      height: 110
    }
  },
  containerStatus: {
    [theme.breakpoints.down("sm")]: {
      height: 322
    },
    [theme.breakpoints.up("sm")]: {
      height: 190
    },
    [theme.breakpoints.up("md")]: {
      height: 120
    },
    backgroundImage: "linear-gradient(to right, #bf272b , #601416 )"
  },

  containerTime: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      paddingTop: 10,
      border: "none",
      borderTop: "1px solid #ffffff",
      borderBottom: "1px solid #ffffff",
      height: 80
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 25,
      height: 117
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 10,
      height: 117
    },
    paddingBottom: 10,
    borderLeft: "1px solid #ffffff"
  },
  containerMaskot: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 50
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 20
    }
  },

  imgBeritaResponsive: {
    // [theme.breakpoints.down("sm")]: {
    //   height: 150
    // },
    // [theme.breakpoints.up("md")]: {
    //   height: 175
    // },
    height: 180,
    width: "100%"
  },

  imgSponsorResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: 200
    },
    [theme.breakpoints.up("md")]: {
      height: 200
    },
    width: "95%"
  },

  spacingTop: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 20
    }
  },

  imgPhotoResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: 110,
      width: "97%"
    },
    [theme.breakpoints.up("md")]: {
      height: 150,
      width: "95%"
    }
  },

  containerResponsive: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 10
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 40
    },
    paddingTop: 20
  },
  txtTime: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 17
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 22
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 36
    },
    color: "white",
    textAlign: "center"
  },
  containerSponsor: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 10
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 40
    },
    paddingTop: 40,
    paddingBottom: 40
  },

  boxKategori1: {
    [theme.breakpoints.down("sm")]: {
      height: 70,
      borderTop: "1px solid #ffffff",
      borderRight: "1px solid #ffffff"
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
      borderLeft: "1px solid #ffffff",
      borderBottom: "1px solid #ffffff"
    },
    paddingTop: 10
  },
  boxKategori2: {
    [theme.breakpoints.down("sm")]: {
      height: 70,
      borderTop: "1px solid #ffffff",
      borderRight: "1px solid #ffffff"
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
      borderLeft: "1px solid #ffffff"
    },
    paddingTop: 10
  },

  boxKategori3: {
    [theme.breakpoints.down("sm")]: {
      height: 70,
      borderTop: "1px solid #ffffff"
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
      borderLeft: "1px solid #ffffff"
    },
    paddingTop: 10
  },

  containerVideo: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "98%"
    },

    marginRight: 10
  },

  txtTitleStatus: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 17
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 22
    },
    color: "white",
    textAlign: "center"
  },

  imgMaskot: {
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
      height: 220
    },
    [theme.breakpoints.up("md")]: {
      // width: '80%',
      height: 265
    }
  },
  txtBerita: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      lineHeight:1,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 15
    },
    color: "#000000"
  },
  txtPhoto: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 13
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14
    },
    color: "#ffffff",
    lineHeight: 1.1,
    textAlign: "center"
  },
  taglineImg: {
    [theme.breakpoints.up("sm")]: {
      width: 150,
      marginLeft: 75
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
      marginLeft: 75
    },
    height: 60,
    marginTop: 25
  },
  statusTitleTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 13
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14
    },
    textAlign: "center",
    color: "#ffffff",
    fontFamily: "Roboto",
    fontWeight: 300
  },
  eventTxt: {
    fontSize: 8,
    textAlign: "center"
  },
  txtVideo: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 10
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 12
    },
    color: "#ffffff",
    fontWeight: 200
  },

  txtTitleVideo: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 16
    },
    color: "#ffffff",
    fontWeight: 200
  },
  titleTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 15
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 18
    },
    fontWeight: 800
  },
  imgSponsor: {
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 70
    },
    [theme.breakpoints.up("md")]: {
      width: 140,
      height: 130
    },
    width: 140,
    height: 130
  }
});

const statusNumberTxt = {
  fontSize: 17,
  textAlign: "center",
  color: "#ffffff",
  fontWeight: 200
};

export default withStyles(useStyles)(Home);
