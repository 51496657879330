import React, {createContext, useReducer, useEffect} from 'react'
import Api from "../Services/Api"
export const VideoContext = createContext()
const localState = JSON.parse(localStorage.getItem("video"));
const VideoContextProvider = (props) =>{
    const [Video, Videodispatch] = useReducer(ContextReducer, localState || [])   
    
    useEffect(() => {
        // console.log('dataNews', dataNews)
        if(Video.length === 0){
            Api.getVideos().then(res=>{
                Videodispatch({type:'SET_DATA', data: res.data});
                localStorage.setItem("video", JSON.stringify(res.data));
            })
        }
    }, [Video]);
    return(
        <VideoContext.Provider value={{Video, Videodispatch}}>
            { props.children }
        </VideoContext.Provider>
    )
}
const ContextReducer = (state, action)=>{
    switch(action.type){
        case 'SET_DATA': 
            return state = action.data
        default:
            return state
    }
}

export default VideoContextProvider

