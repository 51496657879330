import React, { Component, Fragment } from "react";

// * Material UI import
import {
  Typography,
  Grid,
  Button,
  CssBaseline,
} from "@material-ui/core";

import _ from 'lodash';

// Component
import Header from '../Component/Header';
import Footer from '../Component/Footer';

// Image
import AbstractImg from "../../Assets/Images/background_abstract.png";

// Icons

import BreadCrumbs from "../Component/BreadCrumbs";

import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import Menu from '../Component/Menu'

import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"
import { LanguageContext } from "../../Context/languageContext";

class NewsDetail extends Component {
  static contextType = LanguageContext
  constructor() {
    super();
    this.state = {

      news: [],
      content: { tags: [] },
      title : '',
      news_tag: ["PON XX", "Papua", "Menpora", "Kabupate Jayapura", "Venue", "Persiapan PON"],
      contentBerita: {
        title: '',
        content: ''
      },

      contentIndonesia : '',
      contentEnglish : ''
    };
  }

  componentDidMount() {
    // const { title, category } = this.props.match.params;
    // Api.getNews().then(resData=>{
      const limitDataNews = _.take(this.props.news, 10)
      this.setState({ news: limitDataNews });

      const { title, category } = this.props;
      Api.getNewByContent(category, title).then(resData=>{
        this.setState({ content: resData.data[0], contentIndonesia: resData.data[0].content, contentEnglish: resData.data[0].contentEN });
      })
    // })

    // Api.getNewByContent(this.props.category,this.props.title).then(resData=>{
    //   console.log('content', JSON.stringify(resData.data[0]))
        // this.setState({ content: this.state.content[0] });
    //   this.setState({ content: resData.data[0] });
    // })

  }

  newsdetail(category, title) {
    this.props.history.push("/news/news-detail/" + category + "/" + title + "/#");
    // this.setState({ content: this.props.news.filter(x=>x.category === category && x.title === title) });
    Api.getNewByContent(category, title).then(resData=>{
      this.setState({ content: resData.data[0], contentIndonesia: resData.data[0].content, contentEnglish: resData.data[0].contentEN });
    })
  }

  render() {
    const { classes } = this.props;
    const { title, category } = this.props;
    const { Languages, Languagedispatch} = this.context
    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Menu
          titleMenu={
            <div>
            <BreadCrumbs
              firstName={Languages === "Bahasa Indonesia" ? "Berita" : "News"}
              secondName={Languages === "Bahasa Indonesia" ? category : this.state.content.categoryEN }
              colorFirstLink="inherit"
              activeLink="textPrimary"
              firstLink="/news"
              secondLink={"/news/"+category}
            />
            <div style={{marginBottom:40}}/>
            </div>
          }
        />
        {/* menu unit */}
        <main style={{ backgroundImage: 'linear-gradient(to right, #ffffff , #c4c4c4 )' }}>

          <div className={classes.container}>
            <Grid container className={classes.containerResponsive}>
              <Grid item md={7} sm={12} xs={12}>

                <Grid container style={{ marginTop: 10 }}>
                  <Grid item xs={12} className={classes.BeritaImg}>
                    {/* <div style={{ backgroundImage: `url("${url + "/repo/" + this.state.content.picture}")`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: 'auto', minHeight: '98%', width: '100%', backgroundPosition: 'center' }} /> */}
                    <img src={StaticVar.ImageUrl + this.state.content.picture} style={{width:'100%', objectFit:"cover",height:"auto"}}/>
                    <Typography style={{ float: 'left', fontSize: 14, fontWeight: 300, color: '#595959' }}>{this.state.content.create_date}</Typography>
                    <Typography style={{ float: 'right', fontSize: 14, fontWeight: 300, color: '#595959' }}>{Languages === "Bahasa Indonesia" ? "Penulis" : "Contributor"} : {this.state.content.creator}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 style={{ paddingBottom: 0 }}>{Languages === "Bahasa Indonesia" ? category : this.state.content.categoryEN }</h2>
                    <Typography className={classes.titleBeritaTxt}>{Languages === "Bahasa Indonesia" ? title : this.state.content.titleEN }</Typography>
                    <Typography className={classes.contentBeritaTxt}>
                      {Languages === "Bahasa Indonesia" ?
                        <div dangerouslySetInnerHTML={{
                          __html: this.state.contentIndonesia
                        }}></div> : 
                        <div dangerouslySetInnerHTML={{
                          __html: this.state.contentEnglish
                        }}></div>
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} />
              <Grid item md={4} sm={12}>
                <Typography style={{ fontSize: 18, fontWeight: 600 }}>{Languages === "Bahasa Indonesia" ? "Tag Berita" : "Tags"} </Typography>
                <div style={{ marginTop: 10, marginBottom: 30 }}>
                  {
                    this.state.content.tags ? this.state.content.tags.map((item, index) => {
                      return (
                        <Button key={index} style={{ backgroundColor: "#ED3237", color: 'white', margin: 2, borderRadius: 20 }}>{item}</Button>
                      )
                    }) : null
                  }
                </div>
                <Typography style={{ fontSize: 18, fontWeight: 600 }}>{Languages === "Bahasa Indonesia" ? "Berita Lainnya" : "Others"}</Typography>
                <Grid container style={{ marginBottom: 120, marginTop: 10 }}>
                  {

                      _.take(this.props.news, 8).map((item, index) => {
                      return (
                        <Grid item key={index} xs={12} className={classes.spacingBottom} style={{cursor:'pointer'}} onClick={() => { this.newsdetail(item.category, item.title) }}>
                          <Grid container spacing={2}>
                            <Grid item xs={5} style={{ height: 110 }}><div style={{ borderRadius: 5, backgroundImage: `url("${StaticVar.ImageUrl + item.picture}")`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: 'auto', minHeight: '100%', width: '100%', backgroundPosition: 'center' }} /></Grid>
                            <Grid item xs={7}>
                              <Typography style={{ fontSize: 12, fontWeight: 200, color: '#000000' }}>{Languages === "Bahasa Indonesia" ? item.category : item.categoryEN }</Typography>
                              <Typography style={{ fontSize: 12, fontWeight: 200, textAlign: 'left', color: '#000000' }}>{item.create_date}</Typography>
                              <Typography style={{ fontSize: 14, fontWeight: 300, color: '#000000' }}>{Languages === "Bahasa Indonesia" ? item.title : item.titleEN }</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </Grid>

          </div>

        </main>
        {/* Footer */}
        <footer>
          <Footer />
        </footer>
        {/* End footer */}
      </Fragment>
    );
  }
}

NewsDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      background: 'none'
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%'
    }
  },
  BeritaImg: {
    width: '100%',
    // [theme.breakpoints.down('xs')]: {
    //   height: 260,
    // },
    // [theme.breakpoints.up('sm')]: {
    //   height: 350,
    // },
    // [theme.breakpoints.up('md')]: {
    //   height: 420,
    // },
    height:'auto'
  },
  titleBeritaTxt: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 16
  },
  contentBeritaTxt: {
    fontSize: 14,
    fontWeight: 200,
    marginBottom: 20,
  },
  containerResponsive: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 10,
      paddingTop: 10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 40,
      paddingTop: 30,
    },
    
  },
  spacingBottom: {
    marginBottom: 20
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
  }
})

export default withStyles(useStyles)(NewsDetail);