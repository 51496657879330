import axios from 'axios';
import StaticVar from '../Config/StaticVar';

// ===> api create 
const api = axios.create({
  baseURL: StaticVar.Base_Url,
  // timeout: 10000,
  headers:{}
});

// ===> api interceptors 
api.interceptors.request.use(function (config) {
    // set headers after authentication
    config.headers['x-access-token'] = localStorage.getItem("token");
    return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


// ===> api list function request

const getNews = () => api.get('/news');
const getNewByContent = (category, title) => api.get('/news/content/' + category + '/' + title);
const getVideos = () => api.get('/videos');
const getVideoCategories = () => api.get('/videos_categories');
const getVideoByCategory = (category) => api.get('/videos/categoryByName/' + category);
const getVideoByName = (category, title) => api.get('/videos/content/' + category + '/' + title);
const getSponsor = () => api.get('/sponsor');
const getHighligth = () => api.get('/highlight');
const getPhotos = () => api.get('/photos');
const getPhotoByName = (name) => api.get('/photos/categoryByName/'+name);
const getPhotoCategories = () => api.get('/photos_categories');
const getPages = (name) => api.get('/pages/');
const getPageByName = (name) => api.get('/pages/'+name);
const getContentUpdate = () => api.get('/contentupdate');
const getSitemap = () => api.get('/sitemap');

export const apis = {
    getNews,
    getNewByContent,
    getVideos,
    getVideoCategories,
    getVideoByCategory,
    getVideoByName,
    getSponsor,
    getHighligth,
    getPhotos,
    getPhotoByName,
    getPhotoCategories,
    getPages,
    getPageByName,
    getContentUpdate,
    getSitemap
}

export default apis