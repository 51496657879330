import React, {createContext, useReducer, useEffect} from 'react'
import Api from "../Services/Api"
export const HighligthContext = createContext()
const localState = JSON.parse(localStorage.getItem("highligth"));
const HighligthContextProvider = (props) =>{
    const [Highligth, Highligthdispatch] = useReducer(ContextReducer, localState || [])   
    useEffect(() => {
        if(Highligth.length === 0){
            Api.getHighligth().then(res=>{
                Highligthdispatch({type:'SET_DATA', data: res.data});
                localStorage.setItem("highligth", JSON.stringify(res.data));
            })
        }
    }, [Highligth]);
    return(
        <HighligthContext.Provider value={{Highligth, Highligthdispatch}}>
            { props.children }
        </HighligthContext.Provider>
    )
}
const ContextReducer = (state, action)=>{
    switch(action.type){
        case 'SET_DATA': 
            return state = action.data
        default:
            return state
    }
}

export default HighligthContextProvider

