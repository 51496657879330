import React, { Component, Fragment } from "react";

/* Material UI import*/
import {
    Grid
} from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import imageMenu from "../../Assets/Images/image-menu.png";

class Menu extends Component {

    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        const { classes } = this.props

        return (
            <Fragment>
                <div style={{ backgroundImage: 'linear-gradient(to right, #bf272b , #601416 )', marginTop: 80 }}>
                    <div className={classes.container}>
                        <Grid container className={classes.containerResponsive}>
                            <Grid item lg={6} md={6} sm={4} xs={12} style={{marginTop:18}}>
                                {this.props.titleMenu}
                            </Grid>
                            <Grid item lg={6} md={6} sm={8} xs={12}>
                                {this.props.searchBar}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Menu.propTypes = {
    classes: PropTypes.object.isRequired,
};

// ===================> Style Mobile Responsive <==========================

const styles = theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            background: 'none',
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${imageMenu})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            minHeight: '100%',
            backgroundPosition: 'center',

        },
    },
    containerResponsive: {
        [theme.breakpoints.down('sm')]: {
            background: 'none',
            paddingTop:10,
            paddingLeft: 20,
            paddingRight: 30,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop:10,
            paddingLeft: 40,
            paddingRight: 50,
        }
    },
    txtTitle: {
        color: '#ffffff',
        fontWeight: 300,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 25,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 30,
        },
    }
})

export default withStyles(styles)(Menu);