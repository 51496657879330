import React, {createContext, useReducer, useEffect} from 'react'
export const SponsorContext = createContext()
const SponsorContextProvider = (props) =>{
    const [Sponsor, Sponsordispatch] = useReducer(ContextReducer,[])   
    
    // useEffect(() => {
    //     if(localStorage.lang){
    //         Sponsordispatch({type:'SET_DATA', data : localStorage.lang })
    //     }
    // }, [])  
    return(
        <SponsorContext.Provider value={{Sponsor, Sponsordispatch}}>
            { props.children }
        </SponsorContext.Provider>
    )
}
const ContextReducer = (state, action)=>{
    switch(action.type){
        case 'SET_DATA': 
            return state = action.data
        default:
            return state
    }
}

export default SponsorContextProvider

