import React, {createContext, useReducer, useEffect} from 'react'
import Api from "../Services/Api"
export const PhotoContext = createContext()
const localState = JSON.parse(localStorage.getItem("photo"));
const PhotoContextProvider = (props) =>{
    const [Photo, Photodispatch] = useReducer(ContextReducer, localState || [])   
    
    useEffect(() => {
        // console.log('dataNews', dataNews)
        if(Photo.length === 0){
            Api.getPhotos().then(res=>{
                Photodispatch({type:'SET_DATA', data: res.data});
                localStorage.setItem("photo", JSON.stringify(res.data));
            })
        }
    }, [Photo]);
    return(
        <PhotoContext.Provider value={{Photo, Photodispatch}}>
            { props.children }
        </PhotoContext.Provider>
    )
}
const ContextReducer = (state, action)=>{
    switch(action.type){
        case 'SET_DATA': 
            return state = action.data
        default:
            return state
    }
}

export default PhotoContextProvider

