import React, {useEffect, useContext} from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";

// * Screen
import Home from "./Pages/Home/HomeHooks";
import News from "./Pages/NewsAndGallery/NewsHook";
import GalleryPhoto from "./Pages/NewsAndGallery/GalleryPhotoHook";
import VideoLiveStreaming from "./Pages/NewsAndGallery/VideoLiveStreaming";
import DetailVideoLiveStreaming from "./Pages/NewsAndGallery/DetailVideoLiveStreaming";
import CategoryPhoto from "./Pages/NewsAndGallery/CategoryPhoto";
import NewsDetail from "./Pages/NewsAndGallery/NewsDetailHook";
import About from "./Pages/About/About";
import Pages from "./Pages/Pages/Pages";

import CacheBuster from "./CacheBuster";

import "./App.css";
import { NewsContext } from "../src/Context/NewsContext";
import { HighligthContext } from "../src/Context/HighligthContext";
import { VideoContext } from "../src/Context/VideoContext";
import { PhotoContext } from "../src/Context/PhotoContext";
import { SponsorContext } from "../src/Context/SponsorContext";
import Api from "../src/Services/Api"

export default function App() {
  const { Newsdispatch} = useContext(NewsContext)
  const { Highligthdispatch} = useContext(HighligthContext)
  const { Videodispatch} = useContext(VideoContext)
  const { Photodispatch} = useContext(PhotoContext)
  const { Sponsordispatch} = useContext(SponsorContext)
  useEffect(() => {
    console.log('cek use Effect App')
    Api.getContentUpdate().then(res=>{
      res.data.map(x=>{
        var localdata = localStorage.getItem('data'+x.name)
        localStorage.setItem('data'+x.name, x.dateupdate)
        if(localdata){
          if(localdata !== x.dateupdate){
            console.log('getdata dari API', x.name)
            localStorage.setItem('data'+x.name, x.dateupdate)
            if(x.name === "news"){
              Api.getNews().then(res=>{ 
                Newsdispatch({type:'SET_DATA', data:res.data}); 
                localStorage.setItem('news', JSON.stringify(res.data)) 
              })
            }
            if(x.name === "highlight"){
              Api.getHighligth().then(res=>{ 
                Highligthdispatch({type:'SET_DATA', data:res.data}); 
                localStorage.setItem('highligth', JSON.stringify(res.data)) 
              })
            }
            if(x.name === "video"){
              Api.getVideos().then(res=>{ 
                Videodispatch({type:'SET_DATA', data:res.data}); 
                localStorage.setItem('video', JSON.stringify(res.data)) 
              })
            }
            if(x.name === "photo"){
              Api.getPhotos().then(res=>{ 
                Photodispatch({type:'SET_DATA', data:res.data}); 
                localStorage.setItem('photo', JSON.stringify(res.data)) 
              })
            }
          }
          else{
            console.log('getdata dari local data', x.name)
          }
        }
      })
    })
    
    // Api.getHighligth().then(res=>{
    //   Highligthdispatch({type:'SET_DATA', data:res.data});
    // })
    // Api.getVideos().then(res=>{
    //   Videodispatch({type:'SET_DATA', data:res.data});
    //   localStorage.setItem('video', JSON.stringify(res.data))
    // })
    // Api.getPhotos().then(res=>{
    //   Photodispatch({type:'SET_DATA', data:res.data});
    //   localStorage.setItem('photo', JSON.stringify(res.data)) 
    // })
    Api.getSponsor().then(res=>Sponsordispatch({type:'SET_DATA', data:res.data}))
  }, [])
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <Router>
            <Route exact path="/" component={Home} />
            <Route exact path="/news" component={News} />
            <Route exact path="/news/:category" component={News} />
            <Route
              exact
              path="/news/news-detail/:category/:title"
              component={NewsDetail}
            />
            <Route
              exact
              path="/photos/photo-detail/:category/:title"
              component={GalleryPhoto}
            />
            <Route exact path="/photo/gallery" component={GalleryPhoto} />
            <Route exact path="/video" component={VideoLiveStreaming} />
            <Route
              exact
              path="/video/video-detail/:category/:title"
              component={DetailVideoLiveStreaming}
            />
            <Route exact path="/photos" component={CategoryPhoto} />
            <Route
              exact
              path="/photos/category/:category"
              component={GalleryPhoto}
            />
            <Route exact path="/pages/:name" component={Pages} />
            <Route exact path="/about" component={About} />
          </Router>
        );
      }}
    </CacheBuster>
  );
}
